<template>
    <div>
    <!-- DRAFT TABLE -->
        <table v-if="this.document.current_doc_type == 'draft'" class="table is-fullwidth is-hoverable is-size-6 document-table">
            <thead>
                <tr style="font-family: 'Karla', sans-serif;" >
                    <th width="80px">#</th>
                    <th>Statusi</th>
                    <th>POS</th>
                    <th>Krijuar nga</th>
                    <th>Klienti</th>
                    <th>Krijuar me</th>
                </tr>
            </thead>
            <tbody>
                <tr @click.prevent="open_doc(docs)" v-for="docs in document_list" :key="docs.id" class="is-clickable">
                    <td>{{docs.id}}</td>
                    <td :class="
                        docs.status_id == 0 ? 'has-text-info' : 
                        docs.status_id == 1 ? 'has-text-danger' : 
                        docs.status_id == 2 ? 'has-text-success' : ''
                    ">
                        {{  
                            docs.status_id == 0 ? 'E re' :
                            docs.status_id == 1 ? 'Per komfirmim cmimi' : 
                            docs.status_id == 2 ? 'Cmim i konfirmuar' : ''
                        }}
                    </td>
                    <td>
                        {{docs.pos_name}}
                    </td>
                    <td>
                        {{docs.created_by_name}}
                    </td>
                    <td>{{docs.customer_name}}</td>
                    <td>
                        {{ docs.created_at ? date_filter(docs.created_at)  : '' }}
                        <!-- {{docs.created_at}} -->

                    </td>
                </tr>
            </tbody>
        </table>


        <!-- ORDER TABLE -->
        <table v-if="this.document.current_doc_type == 'order'" class="table is-fullwidth is-hoverable is-size-6 document-table">
            <thead>
                <tr style="font-family: 'Karla', sans-serif;" >
                    <th width="80px">ID</th>
                    <th>Statusi</th>
                    <!-- <th>POS</th> -->
                    <th>Krijuar nga</th>
                    <th>Klienti</th>
                    <th>Krijuar me</th>
                </tr>
            </thead>
            <tbody>

                <template  v-for="(doc, index) in document_list" >
                    <tr @click.prevent="open_doc(doc)" v-if="doc" :key="index + ' head'" class="bg-slate-50 is-clickable">
                        <td>
                            {{doc.draft_number}}
                        </td>
                        <td>
                            {{doc.pos_name}}
                        </td>
                        <td>
                            {{doc.created_by_name}}
                        </td>
                        <td>{{doc.customer_name}}</td>
                        <td>
                            {{ doc.created_at ? date_filter(doc.created_at)  : '' }}
                        </td>
                    </tr>
                    <tr @click.prevent="open_doc(d)" :key="index + ' sub ' + index2" v-for="(d, index2) in doc.details" class="is-clickable" >
                       <!-- <td class="pl-4"> ------ {{doc.id}}</td>  -->
                        <td class="pl-4 is-flex">
                            <!-- <i class="fa-light fa-solid fa-arrow-turn-up fa-rotate-90 mr-4 text-slate-300"></i> -->
                            <span class="arrow-icon mr-2">
                                &#8629;
                            </span>
                            <span class="fraction-wrap">
                                {{d.fraction}}
                            </span>
                        </td>
                        <td>
                            {{d.warehouse_name}}
                        </td>
                        <td>
                            {{d.created_by_name}}
                        </td>
                        <td>{{d.customer_name}}</td>
                        <td>
                            {{ d.created_at ? date_filter(d.created_at)  : '' }}
                        </td>
                    </tr>
                </template>
            
            </tbody>
        </table>
    </div>
</template>

<script>
import DocumentServices from "@/services/Document";
import { useDocumentStore } from "@/stores/document";
import { storeToRefs } from 'pinia';
import moment from 'moment'
import permissionMixin from '@/mixins/permissions-mixin.js'
import Api from "@/services/Api";
// import socket from "@/socket.js";
// import { onUnmounted,/* reactive, inject*/ } from "vue";
export default {
    mixins: [permissionMixin],
    data() {
        return {
            // documents: storeToRefs(useDocumentStore()),
            selectedDoc: [],
            document: storeToRefs(useDocumentStore()),
            document_type: 'draft',
        }
    },
    async created () {
        
        this.document.list = await DocumentServices.getPosDocuments(this.document.current_doc_type);
        this.moment = moment;
        this.document_type = this.document.current_doc_type
    },
    methods: {
        async open_doc(doc) {
            // evaluate if order has suborders
            if(doc.has_suborders){
                this.document[this.document_type + '_cart'] = []
                this.document[this.document_type + '_infos'] = []
                for(const d of doc.details){
                    this.selectedDoc = await DocumentServices.getDoc({...d, document_type: this.document.current_doc_type})
                    this.document[this.document_type + '_info'] = this.selectedDoc.info
                    this.document[this.document_type + '_infos'] = [...this.document[this.document_type + '_infos'], ...[this.selectedDoc.info]]
                    this.document[this.document_type + '_cart'] = [...this.document[this.document_type + '_cart'], ...this.selectedDoc.details]
                    this.document['selected_customer'] = this.selectedDoc.selected_customer
                }


                this.$vfm.hideAll()
                
                // await this.get_documents_notes()

            }
            else {
                doc.document_type = this.document.current_doc_type
                this.selectedDoc = await DocumentServices.getDoc(doc)
                this.document[this.document_type + '_info'] = this.selectedDoc.info
                this.document[this.document_type + '_cart'] = this.selectedDoc.details
                this.document['selected_customer'] = this.selectedDoc.selected_customer
                this.$vfm.hideAll()
                await this.get_documents_notes()
            }
            // 
            this.$router.push({ path: '/document_details/' + this.document.current_doc_type + '/' + this.selectedDoc.info.guid })



            // this.$router.push('/sale/' + this.document.current_doc_type + '/' + this.selectedDoc.info.guid)
            // setTimeout(() => {
            //     window.location.reload();
            // }, 500);
        },
        async get_documents_notes(){
            await Api(true).post('/note/get/data', {
                guid: this.document[this.document.current_doc_type + '_info'].guid,
                document_type: this.$route.params.document
            })
            .then(r => {
                // this.notes = {...r.data, edit_mode: false}
                    this.document.notes = [...r.data.map(n => {
                    n.edit_mode = false
                    n.original_content = n.content
                    return n
                })]
            })
        },
        date_filter(date){
            return moment(date).format('DD/MM/YYYY hh:mm')
        }
    },
    computed: {
        document_list(){
            let user = JSON.parse(localStorage.user)
            let list = this.document.list.filter(d => {
                if(this.document[this.document.current_doc_type + '_info'].pos_id){
                    if(this.document[this.document.current_doc_type + '_info'].pos_id == d.pos_id && (user.id == d.created_by || this.am_allowed(this.$route.params.document + '.can_manage_others'))) return true
                    else return false
                } else {
                    if(this.am_allowed('draft.can_confirm_discount')) return true
                    else return false
                }
            })
            // If it is order -> group by guid
            if(this.document.current_doc_type == 'order'){
                // group infos by draft_id function
                var groupBy = function(xs, key) {
                    return xs.reduce(function(rv, x) {
                        (rv[x[key]] = rv[x[key]] || []).push(x);
                        return rv;
                    }, {});
                };

                let grouped_list = groupBy(list, 'draft_guid')
                let keys_list = Object.keys(grouped_list)

                let arr = []

                for (let i = 0; i < keys_list.length; i++){
                    // const element = Object.keys(grouped_list)[index];
                    let ord = {...grouped_list[keys_list[i]][0]} 

                    if(grouped_list[keys_list[i]].length > 1 ){
                        ord.details = [...grouped_list[keys_list[i]]]
                        ord.has_suborders = true
                    }

                    arr.push(ord)
                }


                list = [...arr]
            }


            // Count test
                // this.document.draft_tot_count = list.map(d => {
                //     if(d.status_id <= 2){
                //         return true
                //     }
                // }).length
                // this.document.draft_response_count = list.filter(d => {
                //     if(d.status_id == 2){
                //         return true
                //     }
                // }).length
                // this.document.draft_requested_price_count = list.filter(d => {
                //     if(d.status_id == 1){
                //         return true
                //     }
                // }).length

            return list;
        }
    },
    // onBeforeDestroy() {
    //     socket.disconnect();
    // },
}
</script>

<style>
    .document-table * {
        font-family: 'Karla', sans-serif, FontAwesome;

    }
    .arrow-icon{
        
        display: inline-block;
        transform: scale(-1, 1);
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);


    }
    .fraction-wrap{
        background:#dedede;
        width:20px;
        height:20px;
        display:flex;
        justify-content:center;
        align-items: center;
        border-radius:50%;
        font-size:11px;
    }
</style>