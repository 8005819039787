<template>
    <div >
        <span>
            {{draft_tot_count}}
        </span>
        <span v-if="draft_response_count > 0" class="count-notify is-flex is-justify-content-center is-align-items-center bg-green-200">
            {{draft_response_count}}
        </span>
        <span v-if="draft_requested_price_count > 0" class="requested-count-notify is-flex is-justify-content-center is-align-items-center bg-amber-200">
            {{draft_requested_price_count}}
        </span>
    </div>
</template>

<script>
// this.am_allowed(this.$route.params.document + '.can_manage_others'
import { storeToRefs } from 'pinia';
import { useDocumentStore } from "@/stores/document";
import permissionMixin from '@/mixins/permissions-mixin.js'
export default {
    mixins: [permissionMixin],
    created() {
        },
    data() {
        return {
            document: storeToRefs(useDocumentStore()),
        }
    },
    computed: {
        draft_tot_count(){
            // if(Object.keys(this.document.selected_pos).length == 0 )
            //     return this.document.list.length

            let user = JSON.parse(localStorage.user)
            return this.document.list.filter(d => {
                if(Object.keys(this.document.selected_pos).length == 0 && d.status_id <= 2 && (user.id == d.created_by || this.am_allowed(this.$route.params.document + '.can_manage_others'))) 
                    return true
                else if(this.document.selected_pos.guid == d.pos_id && d.status_id <= 2 && (user.id == d.created_by || this.am_allowed(this.$route.params.document + '.can_manage_others'))){
                    return true
                }
            }).length
        },
        draft_response_count(){
            return this.document.list.filter(d => {
                if(Object.keys(this.document.selected_pos).length == 0 && d.status_id == 2) 
                    return true
                else if(this.document.selected_pos.guid == d.pos_id && d.status_id == 2){
                    return true
                }
            }).length
        },
        draft_requested_price_count(){
            return this.document.list.filter(d => {
                if(Object.keys(this.document.selected_pos).length == 0 && d.status_id == 1) 
                    return true
                else if(this.document.selected_pos.guid == d.pos_id && d.status_id == 1){
                    return true
                }
            }).length
        }
    },
}
</script>

<style>

</style>