<template>
  <div class="column">
    <sales-nav-steps></sales-nav-steps>
    <!-- {{document.draft_cart}} -->
    <div>
      <div style="margin-bottom:145px!important;">
        <div class="my-3">
          <div class="tag is-info is-light m-0 is-light mr-2 px-1"
            style="font-family: 'Karla', sans-serif; font-weight:600;" :class="[
        document[document.current_doc_type + '_info'].status_id == 0 ? 'is-info' :
          document[document.current_doc_type + '_info'].status_id == 1 ? 'is-danger' :
            document[document.current_doc_type + '_info'].status_id == 2 ? 'is-success' : '']">
            <i class="fa-solid fa-circle-info mr-1 "></i>
            {{
                document[document.current_doc_type + '_info'].status_id == 0 ? 'Porosi e re' :
                  document[document.current_doc_type + '_info'].status_id == 1 ? 'Ne pritje te konfirmimit te cmimit' :
                    document[document.current_doc_type + '_info'].status_id == 2 ? 'Cmime te konfirmuara' : ''
            }}
          </div>
          <div class="tag bg-slate-300 mr-2 px-1" style="font-family: 'Karla', sans-serif; font-weight:600;"
            title="Krijuar nga">
            <i class="fa-solid fa-user mr-2 text-slate-300"></i> {{ document[document.current_doc_type +
                '_info'].created_by_name
            }}
          </div>
          <div class="tag bg-slate-300 mr-2 px-1 " style="font-family: 'Karla', sans-serif; font-weight:600;"
            title="Krijuar me">
            <i class="fa-regular fa-calendar-days mr-1 text-slate-300"></i> {{ document[document.current_doc_type +
                '_info'].created_at ? date_filter(document[document.current_doc_type + '_info'].created_at) : ""
            }}
          </div>
          <div class="is-pulled-right">
            <div class="">
              <div class="field is-grouped is-grouped-multiline is-clickable">
                <div @click.prevent="show_product_notes({
                  doc_type: $route.params.document,
                  doc_id: document[document.current_doc_type + '_info'].guid,
                  doc_detail: ''
                })" class="control">
                  <div class="tags has-addons">
                    <span class="tag is-info is-light">
                      <a @click.prevent="() => { }" href="" class="">
                        <i class="fa-regular fa-comment"></i>
                      </a>
                    </span>
                    <span class="tag is-info is-light">
                      {{ notes_count }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-for="(product, index) in doc_list" :key="index">
          <!-- show fraction sub line -->
          <!-- <pre> {{product}} </pre> -->
          <div v-if="document.current_doc_type == 'order' && $route.params.is_order_head">
            <div v-if="index == 0 || (index > 0 && doc_list[index].fraction != doc_list[index - 1].fraction)"
                class="is-flex is-flex-direction-column" >
              <div 
                class="p-3 text-blue-500 is-flex is-align-content-space-between is-justify-content-space-between">
                <div class="p-2 border-1 rounded-full bg-gray-100 text-blue-900 has-text-centered" style="width: 30px;">
                  {{ product.fraction }}</div>
                <a @click.prevent="open_fraction(product)" href="">
                  <span>{{ product.warehouse_name }}</span>
                </a>
              </div>
              <div class="is-flex is-justify-content-flex-end text-slate-300 has-text-centered detail-header" style="">
                <div style="width:200px;" class="">
                  <span class="font-thin">
                    Magazina
                  </span>
                </div>
                <div style="width:120px;">
                  Sasia
                </div>
                <div style="width:145px;">
                  Cmimi
                </div>
              </div>
            </div>
          </div>
          <article :class="{'bg-slate-100': !product.guid}"
            class="media border-b border-t border-slate-200 mb-2 is-align-items-stretch single-cart-product pt-0"
            style="border-bottom-width:1px; font-size:13px; ">
            <div v-if="am_allowed($route.params.document + '.can_edit')" @click.prevent="remove_cart_item(product)"
              class="is-flex is-justify-content-center is-align-items-center px-4 has-text-danger border-r border-l border-slate-200 is-clickable">
              <i class="fa-regular fa-trash-can"></i>
            </div>
            <div class="media-content border-r border-slate-200 pl-3 is-flex is-align-items-top">
              <div class="content is-flex is-flex-direction-column" style="width:100%;">
                <p class="mb-0">
                  <strong class="is-size-6" style="font-family: 'Nunito Sans', sans-serif; ">{{ product.name }}</strong>
                </p>
                <small class="text-slate-300">{{ product.category_name }}</small>
                <div class="is-flex is-justify-content-space-between">
                  <div class="field is-grouped is-grouped-multiline is-clickable">
                    <div
                      @click.prevent="show_product_notes({
                        doc_type: $route.params.document,
                        doc_id: document[document.current_doc_type + '_info'].guid,
                        doc_detail: product.guid },
                        product
                      )"
                      class="control mb-0">
                      <div class="tags has-addons">
                        <span class="tag is-info is-light mb-0">
                          <a @click.prevent="() => { }" href="" class="">
                            <i class="fa-regular fa-comment"></i>
                          </a>
                        </span>
                        <span class="tag is-info is-light mb-0">
                          {{ document.notes.filter(n => n.doc_detail == product.guid).length }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-if="product.accessories_categories && product.accessories_categories.length > 0"
                    @click.prevent="show_product_attributes({
                      doc_detail: product
                    })"
                   class="accessories-wrap tag is-clickable mr-2">
                    <i class="fa-solid fa-list-ul"></i> 
                    <span class="ml-1">{{product.accessories_categories.length}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="product.transportable_service || product.mountable_service"
              class="is-flex is-justify-content-center is-align-items-center  border-r border-slate-200 px-2"
              style="min-width:95px;">
              <div v-if="product.transportable_service > 0">
                <label class="checkbox is-flex mr-1 icon-checkbox-document">
                  <i 
                    :class="{'text-green-600' : product.is_transportable}"
                    class="fa-solid fa-truck-ramp-box "></i>
                  <input v-model="product.is_transportable" @change="change_detail_transport(product)" :true-value="1"
                    :false-value="0" type="checkbox" :disabled="!am_allowed($route.params.document + '.can_edit')"
                    class="display-none" style="display:none;" />
                </label>
              </div>
              <div v-if="product.mountable_service > 0" 
                class="">
                <label 
                  class="checkbox is-flex ml-1 icon-checkbox-document">
                  <i class="fa-solid fa-screwdriver-wrench" :class="{'text-green-600' : product.is_mountable}"></i>
                  <input v-model="product.is_mountable" @change="change_detail_mountable(product)" true-value="1"
                    false-value="0" type="checkbox" :disabled="!am_allowed($route.params.document + '.can_edit')"
                    class="display-none" style="display:none;" />
                </label>
              </div>
            </div>
            <div class="is-flex is-flex-direction-column is-align-items-center border-r border-slate-200 px-2 pt-1"
              style="width:200px;">
              <!-- <span class="text-slate-300" style="font-family: 'Ubuntu', sans-serif; font-weight:100;">
                Magazina
              </span> -->
              <!-- {{product.from_warehouse}} -->
              <div v-if="$route.params.document == 'draft'">
                <span @click="get_available_warehouses(product)" class="is-clickable">
                  {{ product.warehouse_name }}
                </span>
                <div v-if="product.availabla_warehouses">
                  <span v-if="product.availabla_warehouses.length == 0">
                    Nuk ka gjendje ne magazina te tjera.
                  </span>
                  <div v-else class="is-flex">
                    <div class="field has-addons">
                      <div class="control is-expanded">
                        <div class="select is-fullwidth is-small">
                          <select v-model="product.new_warehouse" name="country">
                            <option :value="null">Zgjidh magazinen</option>
                            <option v-for="w, indexaa in product.availabla_warehouses" :key="indexaa"
                              :value="w">{{ w.warehouse_name }} - <span
                                class="has-text-right">({{ w.amount }})</span></option>
                          </select>
                        </div>
                      </div>
                      <div class="control">
                        <button @click.prevent="save_changed_warehouse(product)" class="button is-primary is-small " >
                          <i class="fa-solid fa-check"></i></button>
                      </div>
                      {{product.new_warehouse}}
                    </div>
                    <!-- <button @click.prevent="product.availabla_warehouses = null" class="delete is-small"></button> -->

                  </div>
                </div>
              </div>
              <div v-else>
                <span class="stock-warehouse text-slate-300">
                  {{product.stock_warehouse}}
                </span>
                <br>
                <span>
                  {{ product.warehouse_name }}
                </span>
              </div>
            </div>
            <div class="is-flex is-justify-content-center is-align-items-center border-r border-slate-200 px-2"
              style="min-width:120px;">
              <div class="text-center">
                <!-- <span class="text-slate-300" style="font-family: 'Ubuntu', sans-serif; font-weight:100;">
                  Sasia
                </span> -->
                <product-amount v-if="show_change_amount(product)" :index="index"></product-amount>
                <p v-else>
                  <span class="">{{ amount_filter(product.amount) }} x</span>
                </p>
              </div>
            </div>
            <div class="prod-list-price-col text-right is-flex is-flex-direction-column is-flex is-align-items-center is-justify-content-center"
              style="position:relative;">
              <div class="text-center is-fullwidth" style="width:100%;">
                <p>
                  <span class="text-slate-300 is-pulled-left"
                    style="font-family: 'Ubuntu', sans-serif; position:absolute; top:3px; left:3px;"><i
                      class="fa-solid fa-hand-holding-dollar"></i></span>
                  <span class="mt-2" :class="[product.discount != 0 ? 'line-through' : '']">
                    <!-- {{ price_filter(product.price) }} -->
                    {{ product_total(product) }}
                  </span>
                </p>
                <!-- Discount -->
                <div v-if="document[document.current_doc_type + '_info'].status_id != 0" class="">
                  <div
                    v-if="document[document.current_doc_type + '_info'].status_id == 1 && am_allowed('draft.can_confirm_discount')"
                    class="">
                    <div v-if="!product.discount_type"
                      class="is-flex is-justify-content-center is-align-items-center my-1">
                      <span class="mx-2" style="font-size:10px;">Discount: </span>
                      <a @click.prevent="change_discount_type(product, 1)" href=""
                        class="button is-small mr-3 discount-type-btn">Lek</a>
                      <a @click.prevent="change_discount_type(product, 2)" href=""
                        class="button is-small discount-type-btn"><i class="fa-solid fa-percent"></i></a>
                      <!-- {{product.discount_type}} -->
                    </div>
                    <div v-else class="">
                      <div class="is-flex is-justify-content-center mb-1 is-align-items-flex-end">
                        <span class="mx-1" style="font-size:10px;">Discount: </span>
                        <input v-model="product.discount" @keyup="change_discount_amount(product)" type="text"
                          class="input is-small discount-custom-input">
                        <a @click.prevent="toggle_discount_type(product)" href=""
                          class="button is-small discount-custom-btn">
                          {{ product.discount_type == 1 ? 'Leke' : product.discount_type == 2 ? '%' : '' }}
                        </a>
                      </div>
                      <!-- <div class="field has-addons">
                            <p class="control">
                                <input v-model="product.discount" class="input is-success is-small has-text-right" type="text" placeholder="" >
                            </p>
                            <p class="control">
                                <a @click.prevent="toggle_discount_type(product)" class="button  is-small">
                                    {{ product.discount_type == 1 ? 'Leke' : product.discount_type == 2 ? '%' : '' }}
                                </a>
                            </p>
                        </div> -->

                    </div>
                  </div>
                  <p v-if="document[document.current_doc_type + '_info'].status_id == 2 && product.discount > 0"
                    class="text-center">
                    {{ decimal_filter(product.discount) }} {{ product.discount_type == 1 ? 'Leke' : product.discount_type
                        == 2 ? '%' : ''
                    }}
                  </p>
                </div>
                <!-- Discounted price -->
                <p v-if="product.discount != 0" class="text-emerald-600 my-2">
                  {{ price_filter(discount_price(product)) }}
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>

      <!-- Footer -->
      <div v-if="document[document.current_doc_type + '_info'].id"
        class="price-footer is-flex is-justify-content-right is-flex-direction-column pl-2">
        <div class=" m-4 p-3 ml-auto border border-slate-200" style="min-width:200px;">
          <!-- <span class="mr-2" style=" font-family: 'Karla', san-serif, FontAwesome;">
            Total:
          </span> -->
          <div class="total-document-wrap" style=" font-family: 'Nunito Sans', san-serif, FontAwesome; ">
            <div >
              <!-- style=" font-family: 'Nunito Sans', san-serif, FontAwesome; "> -->
              <p class="is-flex is-justify-content-space-between is-align-items-center text-xs">
                <span style="font-weight:100;" class="is-size-7 is-uppercase text-slate-400 ">Pa TVSH</span>
                <span class="ml-2" > {{totals.no_vat}} </span>
              </p>
              <p class="is-flex is-justify-content-space-between is-align-items-center text-xs">
                <span style="font-weight:100;" class="is-size-7 is-uppercase text-slate-400 ">TVSH</span>
                <span class="ml-2" > {{totals.vat}} </span>
              </p>
              <p class="is-flex is-justify-content-space-between is-align-items-center text-xs">
                <span style="font-weight:100;" class="is-size-7 is-uppercase text-slate-400 ">Transport</span>
                <span class="ml-2" > {{ totals.transport }} </span>
              </p>
              <p class="is-flex is-justify-content-space-between is-align-items-center text-xs">
                <span style="font-weight:100;" class="is-size-7 is-uppercase text-slate-400 ">Montim</span>
                <span class="ml-2" > {{ totals.mountable }} </span>
              </p>
              <p class="is-flex is-justify-content-space-between is-align-items-centers mt-1">
                <span style="font-weight:100;" class="is-size-7 is-uppercase text-slate-400 ">Total</span>
                <span class="ml-2" > 
                  {{ totals.overall_total }}
                </span>
              </p>
              <p  v-if="totals.has_discount"  class="is-flex is-justify-content-space-between is-align-items-centers ">
                <span style="font-weight:100;" class="is-size-7 is-uppercase text-slate-400 ">Zbritje</span>
                <span class="ml-2" > 
                  {{ totals.total_discount }}
                </span>
              </p>
            </div>
            <p v-if="totals.has_discount" class="text-emerald-600 is-pulled-right"
              style="  font-size:22px;">
              {{ totals.discount_price  }} 
            </p>
          </div>
        </div>
        <!-- FOOTER BUTTONS -->
        <div v-if="document[document.current_doc_type + '_cart'].length > 0"
          class="is-flex is-justify-content-flex-end ml-auto mr-4 pb-3">
          <a v-if="am_allowed($route.params.document + '.can_delete')" @click.prevent="delete_draft()" href=""
            class="button is-danger mr-2 is-small">
            <i class="fa-regular fa-trash-can mr-2"></i>
            Anullo porosine
          </a>
          <a v-if="document[document.current_doc_type + '_info'].status_id == 2 && $route.params.document == 'draft'"
            @click.prevent="cancel_discount()" class="button is-warning mr-2 is-small">
            <i class="fa fa-times-circle mr-2"></i>
            Anullo zbritjen e cmimit
          </a>
          <a @click.prevent="request_discount()"
            v-if="am_allowed('draft.can_request_discount') && document[document.current_doc_type + '_info'].status_id != 1 && document[document.current_doc_type + '_info'].status_id <= 2 && $route.params.document == 'draft'"
            href="" class="button is-info is-small">
            <i class="fa-solid fa-hand-holding-dollar mr-2 is-small"></i>
            Kerko cmim</a>
          <a @click.prevent="confirm_discount()"
            v-if="am_allowed('draft.can_confirm_discount') && document[document.current_doc_type + '_info'].status_id == 1 && $route.params.document == 'draft'"
            href="" class="button is-info is-small">
            <i class="fa-solid fa-check mr-2"></i>
            Konfirmo cmim</a>
          <a @click.prevent="create_order()"
            v-if="document[document.current_doc_type + '_info'].status_id != 1 && $route.params.document == 'draft' && am_allowed('order.can_create')"
            href="#" class="button is-success ml-2 is-small">
            Vazhdo me porosine <i class="fa-solid fa-angle-right ml-2"></i></a>

          <a @click.prevent="create_invoice()"
            v-if="$route.params.document == 'order' && am_allowed('order.can_invoice')" href="#"
            class="button is-success ml-2 is-small">
            Vazhdo me porosine <i class="fa-solid fa-angle-right ml-2"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var numeral = require('numeral');
import productAmount from './product-amount.vue';
import salesNavSteps from './sales-nav-steps.vue';
import permissionMixin from '@/mixins/permissions-mixin.js'
import Api from "@/services/Api";
// import draftsTable from './drafts-table.vue';
import { useDocumentStore } from "@/stores/document";
import { storeToRefs } from 'pinia';
import { $vfm } from 'vue-final-modal'
import NoteModal from './notes-modal.vue'
import AccessoriesModal from './accessories-modal.vue'
import moment from 'moment'


export default {
  mixins: [permissionMixin],
  components: { productAmount, salesNavSteps,  /*, draftsTable */ },
  data() {
    return {
      document: storeToRefs(useDocumentStore()),
      
    }
  },
  provide() {
    return {
      products_cart: this.p_cart
    }
  },

  created() {
    // this.$parent.socket_status()
    this.get_documents_notes()
  },
  methods: {
    remove_cart_item(p) {
      if (confirm("Hiq kete produkt nga lista?") == true) {
        Api(true).post('/invoice/remove/draft/detail', {
          detail_guid: p.guid,
          info_guid: this.document[this.document.current_doc_type + '_info'].guid,
          document_type: this.$route.params.document
        })
          .then(res => {
            this.document[this.document.current_doc_type + '_cart'] = [...this.document[this.document.current_doc_type + '_cart'].filter(pp => pp.guid != p.guid)]
            if (this.document[this.document.current_doc_type + '_cart'].length == 0) {
              this.$parent.create_new_draft(this.document[this.document.current_doc_type + '_info'].pos_id)
              // this.$parent.count_nav_docs()
            }
            this.$parent.remove_cart_item(p, res.created_by)
          })
      }
    },
    price_filter(price) {
      return numeral(price).format('0,0.00') + ' Lek'
    },
    product_total(p) {
      return numeral(parseFloat(p.price) * parseFloat(p.amount)).format('0,0.00') + ' Lek'
    },
    decimal_filter(n) {
      return numeral(n).format('0.00')
    },
    amount_filter(n) {
      return numeral(n).format('0,0')
    },
    request_discount() {
      if (confirm("Po kerkoni cmim per kete porosi. Jeni te sigurt?") == true) {
        Api(true).post('/invoice/request/discount', {
          info: {
            'guid': this.document[this.document.current_doc_type + '_info'].guid
          }
        }).then(r => {
          if (r.data.draft_info[0].guid) {
            this.document[this.document.current_doc_type + '_info'].status_id = 1
            this.$toast.success('Kerkesa per cmim u dergua me sukses!')
            // this.$parent.count_nav_docs()

            this.$parent.request_discount_socket(r.data)


          }
        })
      }
    },
    discount_price(p) {
      var discount_val = 0
      if (p.discount_type == 1) {
        // return parseFloat(p.price) - parseFloat(p.discount)
        return parseFloat(p.discount)
      }
      else if (p.discount_type == 2) {
        discount_val = (p.discount / 100) * parseFloat(p.price) * parseFloat(p.amount)
        // return parseFloat(p.price) - discount_val
        return discount_val
      }
      return 0
    },
    confirm_discount() {
      if (confirm("Konfirmo kerkesen per cmim?") == true) {
        Api(true).post('/invoice/confirm/discount', {
          cart: this.document[this.document.current_doc_type + '_cart'],
          info: {
            'guid': this.document[this.document.current_doc_type + '_info'].guid
          }
        }).then(r => {
          this.document[this.document.current_doc_type + '_info'] = { ...r.data.draft_info[0] }
          this.document[this.document.current_doc_type + '_cart'] = [...r.data.draft_details.map(d => {
            d = {
              ...d,
              amount: d.amount,
              discount: d.discount,
              original_price: d.original_price

              // amount: parseFloat(d.amount),
              // discount: parseFloat(d.discount),
              // original_price: parseFloat(d.original_price)
            }
            return d
          })]
          // this.$parent.count_nav_docs()
          this.$parent.confirm_discount_socket(r.data)
        })
      }
    },
    show_change_amount(p) {
      var a = false
      if (this.document[this.document.current_doc_type + '_info'].status_id == 0) a = true
      if (this.document[this.document.current_doc_type + '_info'].status_id == 1 && this.am_allowed('draft.can_confirm_discount')) a = true
      if (this.document[this.document.current_doc_type + '_info'].status_id == 2 && p.discount == 0) a = true
      if (!this.am_allowed(this.$route.params.document + '.can_edit')) a = false
      if(!p.guid) a = false
      // if(this.document[this.document.current_doc_type + '_info'].status_id < 2 || p.discount == 0) a = true

      return a
    },
    delete_draft() {
      if (confirm("Anullo porosine?") == true) {
        Api(true).post('/invoice/delete/draft', {
          info: {
            'guid': this.document[this.document.current_doc_type + '_info'].guid
          },
          document_type: this.$route.params.document
        }).then(r => {
          if (r.data.a == 1) {
            this.$parent.delete_draft(this.document, r.data.created_by)
            this.$parent.create_new_draft(this.document[this.document.current_doc_type + '_info'].pos_id)
          }
          // this.$parent.count_nav_docs()
        })
      }
    },
    cancel_discount() {
      if (confirm('Do fshij te gjitha zbritjet e cmimeve. Vazhdoni?') == true) {
        Api(true).post('invoice/cancel/discount', {
          info: {
            'guid': this.document[this.document.current_doc_type + '_info'].guid
          }
        })
          .then(r => {
            if (r.data.a == 1) {
              if (this.document[this.document.current_doc_type + '_info'].status_id == 2) {
                this.document[this.document.current_doc_type + '_info'].status_id = 1
                this.document[this.document.current_doc_type + '_cart'].discount = 0
                // this.$parent.count_nav_docs()

                this.$parent.cancel_discount_socket(this.document, r.data.created_by)
              }
            }
          })
      }
    },
    show_product_notes(data, detail) {
      if (data.doc_detail != "") {
        this.document.selected_detail = detail
      }
      var params = data
      params.save_note_socket = this.$parent.save_note_socket
      params.delete_note_socket = this.$parent.delete_note_socket
      params.is_order_head = this.$route.params.is_order_head

      $vfm.show({ component: NoteModal, params })
    },
    show_product_attributes(data) {
        
        var params = data
      // params.save_note_socket = this.$parent.save_note_socket
      // params.delete_note_socket = this.$parent.delete_note_socket
      // params.is_order_head = this.$route.params.is_order_head

      $vfm.show({ component: AccessoriesModal, params })
    },
    create_order() {
      if (confirm('Doni te vazhdoni me porosine?') == true) {
        Api(true).post('order/create', { draft_info: this.document[this.document.current_doc_type + '_info'], draft_details: this.document[this.document.current_doc_type + '_cart'] })
          .then(res => {
            // Emit socket in order to see the new draft

            this.$toast.success('Porosia u krijua me sukses!')
            this.$parent.create_order_socket(res.data.new_order_heads, res.data.created_by, this.document[this.document.current_doc_type + '_info'])
            window.location.href = '/sale/draft/0';

            this.document[this.document.current_doc_type + '_info'] = {}
            this.document.draft_cart = []
            this.document.selected_customer = {}

            // 1. Go to the correct route and refresh
            // if(r.data == 1){
            // this.$router.replace({path: '/sale/order'})
            // this.document[this.document.current_doc_type + '_info'] = {}
            // this.document.draft_cart = []
            // this.document.selected_customer = {}
            // }
          })
      }
    },
    date_filter(date) {
      return moment(date).format('DD/MM/YYYY hh:mm')
    },
    toggle_discount_type(p) {
      this.document[this.document.current_doc_type + '_cart'] = [...this.document[this.document.current_doc_type + '_cart'].map(prod => {
        if (prod.guid == p.guid) {
          if (prod.discount_type == 1) {
            prod = { ...p, discount_type: 2 }
          }
          else if (prod.discount_type == 2) {
            prod = { ...p, discount_type: 1 }
          }
        }
        return prod
      })]
    },
    get_available_warehouses(p) {
      if(this.am_allowed(this.$route.params.document + '.can_edit')){
          
        this.document[this.document.current_doc_type + '_cart'] = [...this.document[this.document.current_doc_type + '_cart'].map(d => {
          if (d.guid == p.guid) {
            d = { ...d, show_available_warehouses: true }
        }
        return d
        })]

        Api(true).post('order/get/available/warehouses', { detail: p, pos: this.document[this.document.current_doc_type + '_info'].pos_id })
        .then(res => {
          this.document[this.document.current_doc_type + '_cart'] = [...this.document[this.document.current_doc_type + '_cart'].map(dd => {
            if (dd.guid == p.guid) {
              dd = { ...dd, availabla_warehouses: res.data }
            }
            return dd
          })]

        })
      }
    },
    save_changed_warehouse(p) {
      Api(true).post('order/save/new/warehouse', { detail: p, pos: this.document[this.document.current_doc_type + '_info'].pos_id })
        .then(res => {
          if (res.data == 1) {
            this.document[this.document.current_doc_type + '_cart'] = [...this.document[this.document.current_doc_type + '_cart'].map(dd => {
              if (dd.guid == p.guid) {
                dd = { ...dd, 
                  from_warehouse: p.new_warehouse.warehouse_id, 
                  warehouse_name: p.new_warehouse.warehouse_name, 
                  availabla_warehouses: null 
                }
                this.$toast.success('Magazina u ndryshua me sukses!')
              }
              return dd
            })]

            /*var obj = {}
            obj[this.document.current_doc_type + '_cart'] = temp
            this.document = {...this.document, obj}
            this.document[this.document.current_doc_type + '_cart'] = []
            setTimeout(() => {
              this.document[this.document.current_doc_type + '_cart'] = [...temp]
              this.$parent.save_warehouse_change([...temp])
            }, 100)*/

            // Call Socket
            // var socket_name =  'edit_'+ this.$route.params.document +'_' + this.document[this.document.current_doc_type + '_info'].guid + '_change_warehouse'
            // socket.emit("propagate", {
            //     data: this.document[this.document.current_doc_type + '_cart'],
            //     action: socket_name,
            //     document_type: this.$route.params.document,
            //     // user: 
            // })
          }
        })
    },
    change_discount_type(p, t) {
      this.document.draft_cart = [...this.document.draft_cart.map(d => {
        if (d.guid == p.guid) {
          d = { ...d, discount_type: t }
        }
        return d
      })]
    },
    change_discount_amount(p) {
      this.document.draft_cart = [...this.document.draft_cart.map(d => {
        if (d.guid == p.guid) {
          d = { ...d, discount: p.discount }
        }
        return d
      })]
    },
    change_detail_transport(p) {
      this.document[this.document.current_doc_type + '_cart'] = [...this.document[this.document.current_doc_type + '_cart'].map(dd => {
        if (p.guid == dd.guid) {
          dd = {...p}
          // dd = { ...dd, transportable: dd.transportable == 0 ? dd.transportable = 1 : dd.transportable = 0 }
        }
        return dd
      })]
      this.$parent.create_invoice_draft()
    },
    change_detail_mountable(p) {
      this.document[this.document.current_doc_type + '_cart'] = [...this.document[this.document.current_doc_type + '_cart'].map(dd => {
        if (p.guid == dd.guid) {
          // dd = { ...dd, mountable: dd.mountable == 0 ? dd.mountable = 1 : dd.mountable = 0 }
          dd = {...p}
        }
        return dd
      })]
      this.$parent.create_invoice_draft()
    },
    async get_documents_notes() {
      await Api(true).post('/note/get/data', {
        // guid: this.document[this.document.current_doc_type + '_info'].guid,
        guid: this.$route.params.guid,
        document_type: this.$route.params.document
      })
        .then(r => {
          // this.notes = {...r.data, edit_mode: false}
          this.document.notes = []
          this.document.notes = [...r.data.map(n => {
            n.edit_mode = false
            n.original_content = n.content
            return n
          })]
        })
    },
    open_fraction(p){
      window.location.href = '/document_details/' + this.$route.params.document + '/' + p.info_id
    }
  },
  computed: {
    doc_list() {
      var d = []
      if (this.document) {
        this.document[this.document.current_doc_type + '_cart'].map(dd => {
          dd = { ...dd, show_available_warehouses: dd.show_available_warehouses ?? false, }
            d.push(dd)
        })
      }
      return d
    },
    notes_count() {
      var count = 0
      if (this.document) {
        var notes = this.document.notes
        if (this.$route.params.document == 'order') {
          if (!this.$route.params.is_order_head) {
            // get all the notes of the heads only
            count = notes.filter(n => !n.doc_detail).length

            this.document[this.document.current_doc_type + '_cart'].map(d => {
              notes.map(n => {
                if (n.doc_detail == d.guid) count++
              })
            })
          }
          else {
            count = notes.length
          }
        }
        else if (this.$route.params.document == 'draft') {
          count = notes.length
        }
      }
      return count
    },
    p_cart() {
      return this.document[this.document.current_doc_type + '_cart']
    },
    totals(){
      var tot = { 
        with_vat: 0, 
        no_vat: 0, 
        vat: 0, 
        transport: 0, 
        mountable: 0, 
        total_discount: 0,
        overall_total: 0,
        discount_price: 0,
        has_discount: false,
        
      }

      this.document[this.document.current_doc_type + '_cart'].map(pp => {
        tot.with_vat = tot.with_vat + (parseFloat(pp.price) * parseFloat(pp.amount))
        tot.no_vat = (tot.no_vat + (parseFloat(pp.price) * parseFloat(pp.amount)) ) / (1 + (parseFloat(pp.vat) / 100))
        tot.vat = tot.vat + (tot.with_vat - tot.no_vat)
        if(pp.is_transportable == 1 && pp.transport_price){
          tot.transport = tot.transport + parseFloat(pp.transport_price)
        }
        if(pp.is_mountable == 1 && pp.mountable_price){
          tot.mountable = tot.mountable + parseFloat(pp.mountable_price)
        }
        if(pp.discount != 0)
          tot.total_discount = parseFloat(tot.total_discount) + parseFloat(this.discount_price(pp))
        if(parseFloat(pp.discount) > 0){
          tot.has_discount = true
        }
      })
      
      tot.discount_price = numeral(tot.mountable + tot.transport + tot.with_vat - tot.total_discount).format('0,0.00') + ' Lek'
      tot.overall_total = numeral(tot.mountable + tot.transport + tot.with_vat).format('0,0.00') + ' Lek'

      tot.with_vat = numeral(tot.with_vat).format('0,0.00') + ' Lek'
      tot.no_vat = numeral(tot.no_vat).format('0,0.00') + ' Lek'
      tot.vat = numeral(tot.vat).format('0,0.00') + ' Lek'
      tot.transport = numeral(tot.transport).format('0,0.00') + ' Lek'
      tot.mountable = numeral(tot.mountable).format('0,0.00') + ' Lek'
      tot.total_discount = numeral(tot.total_discount).format('0,0.00') + ' Lek'
      
      return tot
    },

    // cart_total() {
    //   var total = 0
    //   this.document[this.document.current_doc_type + '_cart'].map(pp => {
    //     total = total + parseFloat(pp.price)
    //   })
    //   return numeral(total).format('0,0.00') + ' Lek'
    // },
    // cart_total_no_vat() {
    //   var total = 0
    //   this.document[this.document.current_doc_type + '_cart'].map(pp => {
    //     total = (total + parseFloat(pp.price) ) / (1 + (parseFloat(pp.vat) / 100))
    //   })
    //   return numeral(total).format('0,0.00') + ' Lek'
    // },
    
    discounted_cart_total() {
      var total = 0
      
      
        this.document[this.document.current_doc_type + '_cart'].map(pp => {
          if(pp.discount != 0)
            total = parseFloat(total) + parseFloat(this.discount_price(pp))
        })
        return numeral(total).format('0,0.00') + ' Lek'
    } 
  },
  // onBeforeDestroy() {
  //     socket.disconnect();
  // },

}
</script>

<style>
.sale-list-attr-box {
  border-width: 1px 1px 9px 1px;
  position: relative;
  /* border-bottom-width:9px; */
}

.sale-list-attr-box-name {
  position: absolute;
  top: 0px;
  left: 1px;
}

.sale-list-attr-box-name-wrap {
  position: relative;
  min-height: 15px;
}

.flex-row-container {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  /* justify-content: center; */
}

.flex-row-container>.flex-row-item {
  /* flex: 0 0 20%; */
  /*grow | shrink | basis */
  /* height: 100px; */
}

.line-through {
  text-decoration: line-through;
  color: #cbd5e1;
  font-weight: 100 !important;
}

.price-footer {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: auto;
  backdrop-filter: blur(6px);
  /* font-family: 'Karla', san-serif, FontAwesome; */
}

.single-cart-product {
  min-height: 70px;
}

.single-cart-product * {
  font-family: 'Karla', san-serif, FontAwesome;
  font-weight: 600;

}

.single-cart-product>i {
  font-family: san-serif;
}

.comment-wrap {
  width: 50px;
}

.comment-wrap>span {
  width: 50%;
}

.discount-custom-input {
  max-width: 55px;
  border-width: 0 0 1px;
  box-shadow: none;
  border-radius: 0px !important;
  text-align: right;
  padding: 5px 0px 0px 0px !important;
}

.discount-custom-input:focus {
  border-color: #000;
  box-shadow: none;
}

.discount-custom-btn {
  border-radius: 0px !important;
  border-width: 0 0 1px !important;
  padding-bottom: 0px !important;
}

.discount-custom-btn:focus {
  border-color: #000 !important;
  box-shadow: none !important;
}

.prod-list-price-col {
  width: 145px;
}

.discount-type-btn {
  padding: 0px 8px;
}
.total-document-wrap *{
  font-family: 'Roboto Condensed', san-serif, FontAwesome;
}
.stock-warehouse{
  font-weight:100;
  text-decoration: line-through;
}
.detail-header *{
  font-family: Ubuntu, sans-serif; font-weight: 100;
}

.icon-checkbox-document{
  padding:5px; 
  border: 1px solid #dedede;
  border-radius: 5px;
}

.accessories-wrap{
  /* background-color:#dedede;
  text-decoration:underline;
  border-radius:5px; */

}
</style>
