<template>
    <div>
        <vue-final-modal v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content" @beforeOpen="event => event.ref.params" >
            
            <button class="modal__close" @click="close">
                <i class="fa fa-times"></i>
            </button>
            <span class="modal__title">Shenime
                <a v-if="!show_create_new" @click.prevent="start_create_new_note()" href="" class="ml-2 button is-small">
                    <i class="fa-solid fa-plus"></i>
                </a></span>
            <hr class="my-1">
            <div class="modal__content px-0">
                <div>
                    <!-- <p v-if="!show_create_new" class="is-flex is-justify-content-right">
                        <a @click.prevent="start_create_new_note()" href="">
                            <i class="fa-solid fa-plus"></i>
                        </a>
                    </p> -->
                    <div v-if="show_create_new" class="p-2 bg-green-100">
                        <div class="is-flex is-justify-content-space-between">
                            <small>Krijo shenim te ri</small>
                            <button @click.prevent="cancel_create_new()" class="delete is-small is-pulled-right"></button>
                        </div>
                        <textarea v-model="new_note.content" class="textarea is-small" placeholder=""></textarea>
                        <div class="is-flex ">
                            <a @click.prevent="save_note(new_note)" href="" class="button is-small is-success mt-2 ml-auto">Krijo</a>
                        </div>
                    </div>
                </div>
                <div class="notes-wrap">
                    <div v-for="(note, index) in notes_list" :key="index" class="note-item border border-slate-200 mt-1">
                        <div class="note-head bg-sky-50 p-1" :class="{'bg-sky-200' : !note.doc_detail}" style="font-size:11px;">
                            <p class="is-flex " >
                                <span class="mr-2"><span class="text-gray-400"><i class="fa-solid fa-user fa-sm mr-1"></i></span> {{note.created_by_name}}</span>
                                <span v-if="note.doc_detail" class="mr-2">
                                    <span class="text-gray-400">Produkt:</span> {{note.product_name}}
                                </span>
                                <span class="ml-auto"><span class="text-gray-400"><i class="fa-solid fa-calendar-days"></i>:</span> {{note.created_at}}</span>
                            </p>
                        </div>
                        <div>
                            <p v-if="!note.edit_mode" class="p-2 border-t border-slate-200 text-base"> {{note.content}} </p>
                            <div v-else class="p-2">
                                <div class="is-flex mb-1">
                                    <small class="text-sm">Ndrysho permbajtjen e shenimit</small>
                                    <button @click.prevent="cancel_edit_note(note)" class="delete is-small ml-auto"></button>
                                </div>
                                <textarea v-model="note.content" class="textarea is-small" placeholder=""></textarea>
                                <div class="is-flex ">
                                    <a @click.prevent="save_note(note)" href="" class="button is-small is-success mt-2 ml-auto">Ruaj</a>
                                </div>
                            </div>
                        </div>
                        <div class="is-flex is-justify-content-right">
                            <div @click.prevent="start_edit_note(note)" class="border border-slate-100 is-flex is-justify-content-center is-align-items-center notes-action-icons has-text-info" >
                                <i class="fa-solid fa-pencil"></i>
                            </div>
                            <div @click.prevent="delete_note(note)" class="border border-slate-100 is-flex is-justify-content-center is-align-items-center notes-action-icons has-text-danger" >
                                <i class="fa-solid fa-trash-can"></i>
                            </div>
                        </div>
                        <!-- <pre>{{note}}</pre> -->
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
// import { inject } from 'vue'
import Api from '../../../services/Api'
import { useDocumentStore } from "@/stores/document";
import { storeToRefs } from 'pinia';
// import draftsTable from './drafts-table.vue';
    export default {
        components: {
            VueFinalModal,
            // draftsTable
        },
        data() {
            return {
                notes: [],
                show_create_new: false,
                new_note: {},
                document: storeToRefs(useDocumentStore()),
            }
        },
        computed: {
            notes_list(){
                var list = []
                if(this.$vfm.dynamicModals[0].params.doc_detail != ''){
                    list = this.document.notes.filter(n => n.doc_detail == this.$vfm.dynamicModals[0].params.doc_detail)
                } else {

                    if(this.$vfm.dynamicModals[0].params.doc_type == 'order'){
                        
                        if(this.$vfm.dynamicModals[0].is_order_head){
                            list = this.document.notes
                        }
                        else{
                            // Filter based on details 
                            // get all head notes
                            list = this.document.notes.filter(n => !n.doc_detail)

                            // add all the detail notes
                            this.document[this.document.current_doc_type + '_cart'].map(d => {
                                this.document.notes.map(n => {
                                    if(n.doc_detail == d.guid) list.push(n)
                                })
                            })
                        }
                    }
                    else if (this.$vfm.dynamicModals[0].params.doc_type == 'draft'){
                        return this.document.notes
                    }
                }
                return list.sort((a , b) => b.id - a.id)
            }
        },
        methods: {
            start_create_new_note(){
                this.show_create_new = true
            },
            cancel_create_new(){
                this.new_note = {}
                this.show_create_new = false
            },
            save_note(n){
                Api(true).post('/note/save', {params: this.$vfm.dynamicModals[0].params, data: n})
                .then(r => {
                    if(r.data.list.length > 0){
                        var is_new = false
                        if(n.guid){
                            // EDIT return
                            this.document.notes = [...this.document.notes.map(nn => {
                                if(nn.guid == r.data.list[0].guid){
                                    nn = {...r.data.list[0], edit_mode: false, original_content: r.data.list[0].content}
                                }
                                return nn
                            })]
                        } else {
                            is_new = true
                            // CREATE return
                            this.document.notes.unshift({...r.data.list[0], edit_mode: false, original_content: r.data.list[0].content})
                            this.cancel_create_new()
                        }

                        this.$vfm.dynamicModals[0].params.save_note_socket(r.data.list[0], is_new, r.data.created_by)
                        // var is_new = n.guid ? true : false
                        // this.$parent.save_note_socket(is_new)
                        // this.$emit("save_note_emit", r.data);
                    }
                })
            },
            start_edit_note(note){
                this.document.notes = [...this.document.notes.map(n => {
                    if(n.guid == note.guid){
                        n.edit_mode = true
                    }
                    return n
                })]
            },
            cancel_edit_note(note){
                this.document.notes = [...this.document.notes.map(n => {
                    if(n.guid == note.guid){
                        n.edit_mode = false
                        n.content = n.original_content
                    }
                    return n
                })]
            },
            delete_note(note){
                if (confirm("Doni te fshini kete shenim?") == true){
                    Api(true).post('/note/delete', note)
                    .then(r => {
                        if(r.data.a == 1){
                            this.document.notes = [...this.document.notes.filter(n => n.guid != note.guid)]
                            this.$vfm.dynamicModals[0].params.delete_note_socket(note, r.data.created_by)
                        }
                    })
                }
            }
        },
        async created () {
        },
        
    }
</script>

<style scoped>
hr {
    border: 1px solid #ccc;
    margin: 10px 0;
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__content {
    padding: 10px;
    font-size: 22px;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.notes-action-icons{
    font-size:10px;
    padding:4px 6px;
}
</style>

<style scoped>
.dark-mode div::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>