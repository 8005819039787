<template>
    <div class="columns is-mobile" style="height:100%;">
        <sales-customers></sales-customers>
        <sales-left-search></sales-left-search>
        <sales-product-list :products_cart="products_cart"></sales-product-list>
        <!-- <pre> {{document}} </pre> -->
    </div>
</template>

<script>
import salesLeftSearch from "./components/sales-left-search";
import salesProductList from "./components/sales-product-list";
// import salesCustomers from "./components/sales-customers";
import Api from "@/services/Api";
import { useDocumentStore } from "@/stores/document";
// import { usePosStore } from "@/stores/pos";
import { storeToRefs } from 'pinia';
import permissionMixin from '@/mixins/permissions-mixin.js'
import socket from '@/socket.js';
import { onUnmounted, /*reactive, */ inject } from 'vue';
import DocumentServices from "@/services/Document";
import documentMixin from '@/mixins/document-mixin.js'
// var numeral = require('numeral');
export default {
    setup() {
        const toast = inject("toast");
        let documents = useDocumentStore()
        socket.on("welcome", data => {
            console.log('welcome', data, socket)
        })
        socket.connect()

        socket.on('propagate', data => {
            // toast.success( "Test toaster");

            if(data.action == 'request_discount'){
                documents.list = [...documents.list.map(d => {
                    if(d.guid == data.data.draft_info[0].guid){
                        d.status_id = data.data.draft_info[0].status_id
                        toast.show(  "Perdoruesi " + "<b>"+ data.created_by.name +"</b>" + " kerkon ulje cmimi per draftin <b>"+ data.data.draft_info[0].id +".<b>", { ...{
                            duration: false,
                            type: 'success',
                            onClick: () => {
                            }
                        }});
                    }
                    return d
                })]
                // Update in single
                if(data.data.draft_info[0].guid == documents.draft_info.guid){
                    documents.draft_info = {...data.data.draft_info[0]}
                    documents.draft_cart = [...data.data.draft_details]
                }
                // toast.success( "Perdoruesi " + "<b>" +data.created_by.name + "</b>" + " kerkon ulje cmimi per draftin <b>"+ data.data.draft_info[0].id +".<b>");
            }
            if(data.action == 'confirm_discount'){
                // Update un table list
                documents.list = [...documents.list.map(d => {
                    if(d.guid == data.data.draft_info[0].guid){
                        d.status_id = data.data.draft_info[0].status_id
                        // Only in table (not in single)
                        if(data.data.draft_info[0].guid != documents.draft_info.guid){
                            toast.show( "Kerkesa per cmim per draftin <strong style='color:#fff;'>"+ data.data.draft_info[0].id +"</strong> u konfirmua nga "+ data.created_by.name  +".", { ...{
                                duration: false,
                                type: 'success',
                                onClick: () => {
                                }
                            }});
                        }
                    }
                    return d
                })]
                // Update in single
                if(data.data.draft_info[0].guid == documents.draft_info.guid){
                    documents.draft_info = {...data.data.draft_info[0]}
                    documents.draft_cart = [...data.data.draft_details]
                    toast.show( "Kerkesa per cmim u konfirmua nga perdoruesi '"+ data.created_by.name  +"'.", { ...{
                        duration: false,
                        type: 'success'
                    }});
                }
            }
            if(data.action == "document_count") {
                documents.count.draft_tot_count = data.data.count.draft_tot_count
                documents.count.draft_response_count = data.data.count.draft_response_count
                documents.count.draft_requested_price_count = data.data.count.draft_requested_price_count
            }
            if(data.action == 'create_new_' + data.document_type ){
                var is_assigned_to_pos_create = false
                documents.assigned_pos_list.map(p => {
                    if(p.guid == data.data.draft_info[0].pos_id) is_assigned_to_pos_create = true
                })
                if(documents.selected_pos.guid == data.data.draft_info[0].pos_id || is_assigned_to_pos_create){
                    documents.list.unshift(data.data.draft_info[0])
                    var doc_name = ''
                    if(data.document_type == 'draft') doc_name = 'draft'
                    else if(data.document_type == 'order') doc_name = 'porosi'
                    toast.success("Persoruesi: '"+ data.data.created_by.name +"' krijoi nje "+ doc_name +" te ri.")
                }
            }

            if(data.action == "edit_"+ data.document_type +"_" + documents.draft_info.guid) {
                //Update single
                if(data.data.draft_info[0].guid == documents.draft_info.guid){
                    documents.draft_info = {...data.data.draft_info[0]}
                    documents.draft_cart = [...data.data.draft_details]
                    toast.success("Persoruesi: '"+ data.data.created_by.name +"' modifikoi draftin.")
                    // toast.success('Artikulli u shtua me sukses')
                }
            }

            if(data.action == "remove_item_" + documents.draft_info.guid) {
                documents.draft_cart = [...documents.draft_cart.filter(dc => dc.guid != data.data.guid)]
                toast.success("Artikulli u fshi nga perdoruesi "+ data.created_by.name +".")
            }
            if(data.action == "empty_draft") {
                documents.draft_info = {
                    pos_id: data.data
                }
                documents.draft_cart = []
                documents.selected_customer = {}
            }
            if(data.action == "delete_"+ data.document_type) {
                // Update un table list
                documents.list = [...documents.list.filter(d => d.guid != data.data.draft_info.guid)]


                // Update if document is the current one
                if(data.data.draft_info.guid == documents.draft_info.guid) {
                    documents.draft_info.guid = ''
                    documents.draft_cart = []
                    documents.selected_customer = {}
                }

                // check if the document pos is assigned to user
                var is_assigned_to_pos_delete = false
                documents.assigned_pos_list.map(p => {
                    if(p.guid == data.data.draft_info.pos_id) is_assigned_to_pos_delete = true
                })

                if(documents.selected_pos.guid == data.data.draft_info.pos_id || is_assigned_to_pos_delete){
                    toast.success("Porosia u anullua nga perdoruesi "+ data.created_by.name +".")
                }
            }

            if(data.action == "cancel_discount") {
                // Update un table list
                documents.list = [...documents.list.map(d => {
                    if(d.guid == data.data.draft_info.guid){
                        d.status_id = data.data.draft_info.status_id
                    }
                    return d
                })]
                // Update in single
                if(documents.draft_info.guid == data.data.draft_info.guid){
                    documents.draft_info.status_id = 1
                    documents.draft_cart.discount = 0
                }
                toast.success(documents.draft_cart.length == 1 ? "Zbritja e cmimit u anullua nga perdoruesi " + data.created_by.name + "." : "Zbritjet e cmimeve u anulluan nga perdoruesi " + data.created_by.name + ".")
            }

            if(data.action == "create_note_" + documents.draft_info.guid) {
                documents.notes.unshift(data.data)
                toast.success("Perdoruesi '"+ data.created_by.name +"' krijoi nje shenim i ri.")
            }
            if(data.action == "edit_note_" + documents.draft_info.guid) {
                documents.notes = [...documents.notes.map(n => {
                    if(data.data.guid == n.guid ){
                        n.content = data.data.content
                    }
                    return n
                })]
            }
            if(data.action == "delete_note_" + documents.draft_info.guid) {
                documents.notes = [...documents.notes.filter(n => n.guid != data.data.guid)]
                toast.success("Perdoruesi '"+ data.created_by.name +"' fshiu nje shenim.")
            }

            if(data.action == "edit_"+ data.document_type +"_" + documents.draft_info.guid  + "_change_warehouse") {
                //Update single
                documents.draft_cart = [...data.data]
                toast.success("Persoruesi: '"+ data.created_by.name +"' modifikoi draftin.")
            }

        })
        
        onUnmounted(() => {
            socket.off('welcome')
            socket.off('propagate')
            socket.disconnect()
        })
        return {
            documents,
            
        };
    },
    mixins: [permissionMixin, documentMixin],
    data() {
        return {
            products_cart: [],
            show_customers_dropdown: false,
            current_user: {},

            document: storeToRefs(useDocumentStore()),
            pos_loading: true,
            // current_doc_type: 'draft'

            // store_pos: storeToRefs(usePosStore()),
            // assigned_pos_list: [],
            
        }
    },
    components:{
        salesLeftSearch,
        salesProductList,
        // salesCustomers
    },

    async created(){
        if(!this.am_allowed(this.$route.params.document + '.can_see')){
            window.location.href = '/home'
            // this.$router.replace({path: '/home'})
        }
        this.current_user = JSON.parse(localStorage.user)
        // this.count_nav_docs()
        this.get_assigned_pos(this.current_user)
        if(this.$route.params.document){
            this.document.current_doc_type = this.$route.params.document
        }
        if(this.$route.params.guid != '0' && this.document[this.$route.params.document + '_cart'].length == 0){
            var selectedDoc = await DocumentServices.getDoc({
                guid: this.$route.params.guid,
                document_type: this.$route.params.document
            }, this.$route.params.is_order_head ? this.$route.params.is_order_head : 0)
            this.document[this.$route.params.document + '_info'] = {...selectedDoc.info}
            this.document[this.$route.params.document + '_cart'] = [...selectedDoc.details]
            this.document['selected_customer'] = selectedDoc.selected_customer
            this.$document.title = this.$route.params.document + ' - ' + selectedDoc.info.id
        }
        this.$document.title = this.$route.params.document
    },
    methods: {
        socket_status(){
        },
        async create_invoice_draft(){

            var document_create_edit = await this.create_edit_document_mixin(this.document.current_doc_type)
            if(document_create_edit.message  == 'no_pos'){
                this.$toast.error( 'Zgjidh POS.') // Zgjidh pos
            } 
            // If POS is selected
            else {
                // If have permissions to proceed
                if(document_create_edit.can_proceed){
                    // If Server error (500)
                    if(document_create_edit.server_error){
                        this.$toast.error(document_create_edit.message == 'server_error' ? 'Server error' : '' )
                    } 
                    else {
                        // If have permissions AND Success call
                        if(document_create_edit.success){
                            // this.$toast.success(document_create_edit.message == 'success_request' ? 'aaa Ndryshimi u ruajt me sukses.' : '') // Ndryshimi juaj u ruajt me sukses

                            this.$toast.show(document_create_edit.message == 'success_request' ? 'Ndryshimi u ruajt me sukses.' : '', { ...{
                                // duration: false,
                                type: 'success',
                                position: 'bottom-left'
                               
                            }});


                            // Call Socket
                            var socket_name =  document_create_edit.is_new ? 'create_new_' + this.$route.params.document : 'edit_'+ this.$route.params.document +'_' + this.document.draft_info.guid
                            socket.emit("propagate", {
                                data: document_create_edit.res.data,
                                action: socket_name,
                                document_type: this.$route.params.document,
                                // user: 
                            })
                        }
                    }
                } 
                // Not allowed from permissions
                else {
                    // When permissions doesn't allow
                    if(document_create_edit.message == 'missing_permission'){
                        this.$toast.error(
                            'Ju nuk keni te drejta per te '+
                            (document_create_edit.missing_permissions == 'create' ? 'krijuar' : 'modifikuar')
                             +' nje dokument!'
                        )
                    }
                }
            }

        },
        // save_invoice_draft(){
        //     Api(true).post('/invoice/draft/save', {
        //         cart: this.document.draft_cart, 
        //         info: this.document.draft_info,
        //     })
        // },
        // select_document(i, d){
        //     this.document.draft_info = {...i}
        //     this.document.draft_cart = [...d]
        // },
        create_new_draft(){

            window.location.href =  '/sale/' + this.$route.params.document + '/0'

            // this.document[this.document.current_doc_type + '_info'] = {
            //     pos_id: p
            // }
            // this.document[this.document.current_doc_type + '_cart'] = []
            // this.document.selected_customer = {}


            // this.$router.push('/sale/' + this.$route.params.document + '/0')
            // setTimeout(() => {
            //     window.location.reload();
            // }, 500);

        },

        request_discount_socket(a){
            // this.count_nav_docs()

            socket.emit("propagate", {
                data: a,
                action: 'request_discount'
            })
        },
        confirm_discount_socket(a){
            // this.count_nav_docs()
            socket.emit("propagate", {
                data: a,
                action: 'confirm_discount'
            })           
        },
        cancel_discount_socket(a, created_by) {
            socket.emit("propagate", {
                data: a,
                action: 'cancel_discount',
                created_by: created_by
            })
        },
        remove_cart_item(a, created_by) {
            socket.emit("propagate", {
                data: a,
                created_by: created_by,
                action: 'remove_item_' + this.document[this.document.current_doc_type + '_info'].guid
            })
        },
        delete_draft(a, created_by) {
            socket.emit("propagate", {
                data: a,
                action: 'delete_' + this.$route.params.document,
                document_type: this.$route.params.document,
                created_by: created_by
            })
        },
        async get_assigned_pos(){
            this.pos_loading = true
            await Api(true).post('/invoice/get/assigned/pos', { user: this.current_user })
            .then(res => {
                this.document.assigned_pos_list = res.data
                if(res.data.length == 1){
                    // Assign the only pos
                    this.document[this.document.current_doc_type + '_info'].pos_id = res.data[0].guid
                    this.document.selected_pos = res.data[0]
                }
            })
            this.pos_loading = false
        },
        save_note_socket(data, is_new, created_by){
            socket.emit("propagate", {
                data: data,
                created_by: created_by,
                action: is_new ?
                 'create_note_' + this.document[this.document.current_doc_type + '_info'].guid 
                 : 'edit_note_' + this.document[this.document.current_doc_type + '_info'].guid
            })
        },
        delete_note_socket(data, created_by){
            socket.emit("propagate", {
                data: data,
                created_by: created_by,
                action: 'delete_note_' + this.document[this.document.current_doc_type + '_info'].guid 
            })
        },
        create_order_socket(orders, created_by, old_draft){
            socket.emit("propagate", {
                data: orders,
                created_by: created_by,
                action: 'create_new_order',
                old_draft: old_draft,
                document_type: this.$route.params.document
            })
        },
        // new_note_socket(data, is_new){
        // }
        save_warehouse_change(cart) {
            // Call Socket
            var socket_name =  'edit_'+ this.$route.params.document +'_' + this.document[this.document.current_doc_type + '_info'].guid + '_change_warehouse'
            socket.emit("propagate", {
                data: cart,
                action: socket_name,
                document_type: this.$route.params.document,
                // user: 
            })
        }
    },
    // onBeforeDestroy() {
    //     socket.disconnect();
    // },

}
</script>

<style>
    .sale-product-search{

    }

</style>