<template>
    <div>
        <vue-final-modal v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content">
            <button class="modal__close" @click="close">
                <i class="fa fa-times"></i>
            </button>
            <span class="modal__title">Rezervime</span>
            <hr>
            <div class="modal__content px-0">
                <drafts-table></drafts-table>
            </div>
        </vue-final-modal>
    </div>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import draftsTable from './drafts-table.vue';
    export default {
        components: {
            VueFinalModal,
            draftsTable
        },
        data() {
            return {
            }
        },
        methods: {

        },
        async created () {
        },
        
    }
</script>

<style scoped>
hr {
    border: 1px solid #ccc;
    margin: 10px 0;
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__content {
    padding: 10px;
    font-size: 22px;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>