import Api from "@/services/Api";
export default {
	async getDetailsTypes(r) { 
		let detailTypes = [];
		await Api(true).post("details/types", r).then((response) => {
			detailTypes = response.data.map((a) => {
          var aa = { ...a, id: a.id, label: a.name };
          return aa;
        });

		})
		return detailTypes;
	}
}
