import Api from "@/services/Api";

export default {
    async getDocument() {
        var documents = []
        await Api(true).post('document/list', {})
        .then(r => {
            documents = r.data
        })
        return documents
    },

    async getDoc(doc, is_order_head = 0) {
        var document = {}
        await Api(true).post('document/get_doc', {
            'doc': doc, 
            'is_order_head': is_order_head
        })
        .then(r => {
            document = r.data
        })
        return document
    },

    async getPosDocuments(data) {
        var documents = {}
        await Api(true).post('document/get/pos/documents', {document_type: data})
        .then(r => {
            documents = r.data
        })
        return documents
    }

    
} 