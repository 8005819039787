import { defineStore } from 'pinia'

export const useDocumentStore = defineStore({
  id: 'document',
  state: () => ({
		draft_info: {
      pos_id: ''
    },
    draft_cart: [],
      
    order_info: {},
    order_cart: [],
    order_infos: [],
    selected_customer: {},
    list: [],
    count: {
      draft_tot_count: "",
      draft_response_count: "",
      draft_requested_price_count: ""
    },
    
    assigned_pos_list: [],
    selected_pos: {},

    assigned_pos_warehouses: [],
    selected_pos_warehouse: {},
    
    notes: [],
    selected_detail: {},
    current_doc_type: '',

    in_store: 0

    //Count test
    // draft_tot_count: 0,
    // draft_response_count: 0,
    // draft_requested_price_count: 0

  }),
  getters: {
  }, 
  actions: {
    editDetails(doc_type, details){
      this[doc_type + '_cart'] = [...this[doc_type + '_cart'].filter(a => a.id != null)]
      // console.log(this[doc_type + '_cart'], 'from store cart')
      this[doc_type + '_cart'] = [...JSON.parse(JSON.stringify(details))]
      // console.log(this[doc_type + '_cart'], 'from store cart')
    }
  }
})
