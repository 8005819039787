<template>
    <div class="column bg-sky-700 has-text-white sale-product-search"
        :class="[show_product_search ? 'is-4' : 'is-0 p-0']"> 
        <span @click.prevent="toggle_product_search" class="toggle-product-search bg-sky-100">
            <i class="fas " :class="[show_product_search ? 'fa-angle-left' : 'fa-angle-right']"></i>
        </span>
        <div v-if="show_product_search" class="pb-3" style="">
            <div v-if="!$parent.document[$parent.document.current_doc_type + '_info'].pos_id" 
                class="p-2 mb-2 border border-sky-200 rounded"  
                style="width: fit-content; margin: auto;">
                    <i class="fa-solid fa-triangle-exclamation mr-2"></i>
                    <a class="is-pulled-right" >Zgjidh POS</a>
            </div>
            Kerko produkt 
            <div class="field has-addons">
                <div class="control is-flex-grow-1">
                    <input v-model="prod_search_val" @keyup.enter="search_product()" class="input is-small" type="text" placeholder="Emri, kodi, pershkrimi">
                </div>
                <div class="control">
                    <a v-if="!search_loading" @click.prevent="search_product()" class="button is-info is-small">
                        <i class="fa-solid fa-magnifying-glass"></i>
                    </a>
                    <a v-else href="" @click.prevent="() => {}" class="button is-info is-small">
                        <i class="fa fa-spinner fa-spin"></i>
                    </a>
                </div>
            </div>
            <!-- <input class="input is-small mb-3" type="text" placeholder="Text input"> -->
            <div v-if="search_results.length > 0">
                <div v-if="search_loading">
                </div>
                <div v-for="(type, index) in Object.keys(product_accessory_list)" :key="index">
                    <p class="bg-sky-600 p-2 is-capitalised">{{translation[type]}}</p>
                    <div v-for="(item, indexa) in product_accessory_list[type]" :key="indexa" class="ml-2 mt-2  ">
                        <article class="media border-b-2 border-slate-700 pb-2 mb-2" style="border-bottom-width:1px;">
                            <figure class="media-left">
                                <p class="image is-32x32">
                                    <img src="https://bulma.io/images/placeholders/128x128.png">
                                </p>
                            </figure>
                            <div class="media-content has-text-white">
                                <div class="content">
                                    <p>
                                        <strong class="has-text-white">
                                            {{item.name}}
                                        </strong> 
                                        <small class="text-slate-300 ml-2">
                                            (Gjendje: {{amount_filter(item.amount)}})
                                        </small>
                                        <br>
                                        <small class="text-slate-300">{{item.category_name}}</small>
                                    </p>
                                </div>
                            </div>
                            <div class="is-align-self-center">
                                <a v-if="!is_saving" @click.prevent="add_to_cart(item)" href="">
                                    <i class="fas fa-plus mx-2"></i>
                                </a>
                            </div>
                        </article>

                    </div>

                </div>
            </div>
            <!-- <div>
                <a @click.prevent="toggle_show_categories()" class="is-fullwidth " href="">
                    Categories 
                    <i class="fa-solid fa-angle-down "></i>
                </a>
                <div v-if="show_category_list">
                    <Tree v-if="categories.list" :items="categories.list" :flat="true" />
                </div>
            </div> -->

        </div>
    </div>
</template>

<script>
// import Tree from "@/components/Tree.vue";
import { storeToRefs } from "pinia";
import { useCategoryStore } from "@/stores/category";
import CategoriesServices from "@/services/Category";
import Api from "@/services/Api";
var numeral = require('numeral');
import documentMixin from '@/mixins/document-mixin.js'
export default {
  components: {
    // Tree,
  },
    mixins: [documentMixin],
    data() {
        return {
            show_product_search: false,
            categories: storeToRefs(useCategoryStore()),
            related_entity: "products",

            prod_search_val: "",
            search_results: [],
            search_loading: false,
            show_category_list: false,
            is_saving: false,
            translation: {
                'products': 'Produkte',
                'accessories': 'Aksesore'
            }

        }
    },
    async created() {
        // this.related_entity = this.$route.params.related_entity;
        this.getInitialData();
    },
    methods: {
        toggle_product_search(){
            this.show_product_search = !this.show_product_search
        },
        async getInitialData(){
            if (!this.related_entity) return;

            this.categories.flat_list = [];
            this.categories.flat_list = await CategoriesServices.getCategories(
                this.related_entity
            );
            this.categories.list = [];
            this.categories.list = await CategoriesServices.getTreeCategories(
                this.categories.flat_list,
                "id"
            );

            let attr_list = await CategoriesServices.getAttributesList(
                this.parent_cat,
                this.related_entity
            );
            this.categories.attributes = attr_list.map((d) => {
                d.id = d.guid;
                d.label = d.name;
                return d;
            });

            // let parent_attr_list = await CategoriesServices.getParentAttributeList(this.parent_cat, this.related_entity)
            // this.categories.attributes = parent_attr_list.map(d => {
            //   d.id = d.guid
            //   d.label = d.name
            //   return d
            // })

            let entity_attributes = await CategoriesServices.getEntityAttributes(
                this.related_entity
            );
            this.categories.categories_attributes = entity_attributes.map((d) => {
                d.id = d.guid;
                d.label = d.name;
                return d;
            });

            // this.$root.setCurrentRoute("kliente-kategori");
        },
        search_product(){
        
            if(this.prod_search_val != ""){
                this.search_loading = true
                this.search_results = []
                Api(true).post('/search/product/sale', {search: this.prod_search_val, pos: this.$parent.document[this.$parent.document.current_doc_type + '_info'].pos_id})
                .then(res => {
                    if(res.data.length > 0){
                        res.data.map(r => {
                            this.search_results.push(r)
                        })
                        this.prod_search_val = ''
                    }
                    this.search_loading = false
                }).catch(() => {
                    this.search_loading = false
                })
            }
        },
        async add_to_cart(p){

            var add_ca = await this.add_to_cart_mixin(p)

            if(!add_ca.success){
                if(add_ca.message == 'no_pos'){
                    this.$toast.error('Zgjidh POS')
                }
                else if(add_ca.message == 'missing_permission'){
                    this.$toast.error(
                        'Ju nuk keni te drejta per te '+
                        (add_ca.missing_permissions == 'create' ? 'krijuar' : 'modifikuar')
                         +' nje dokument!'
                    )
                }
                // this.$parent.show_customers_dropdown = true
            } else {
                // Save dhe document
                this.is_saving = true
                await this.$parent.create_invoice_draft()
                this.is_saving = false
                
                this.show_product_search = false
                this.search_results = []
            }

        },
        toggle_show_categories(){
            this.show_category_list = !this.show_category_list
        },
        open_select_customer(){
            this.$parent.show_customers_dropdown = true
        },
        amount_filter(price){
            return numeral(price).format('0,0')
        },
    },
    computed: {
        product_accessory_list(){
            let list = {
                products: [],
                accessories: []
            }

            this.search_results.map(s => {
                if(s.is_accessory == 0) list['products'].push(s)
                else if(s.is_accessory == 1) list['accessories'].push(s)
            })
            return list
        }
    },

}
</script>

<style>
    .sale-product-search{
        position:relative;
        padding-bottom:30px!important;
        z-index:9;
    }
    .toggle-product-search{
        position: absolute;
        top: 12px;
        right: -45px;
        /* background: #000; */
        width: 45px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* z-index: 999; */
        cursor: pointer;
        color:#222;
    }
    .closed-toggle-product{
    }
    
</style>