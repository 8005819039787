<template>
    <div class="sales-customers-wrap">
        <div class="dropdown is-right is-active">
            <div @click.prevent="toggle_customers_dropdown" class="dropdown-trigger is-flex">
                <span v-if="$parent.$parent.$parent.document.selected_customer" class="mr-2 mt-1">
                    {{$parent.$parent.$parent.document.selected_customer.firstname}}
                    {{$parent.$parent.$parent.document.selected_customer.lastname}}
                </span>
                <button class="button is-rounded show-customers-btn is-small bg-yellow-600 text-white" aria-haspopup="true" aria-controls="dropdown-menu6">
                    <span class="icon is-small">
                        <i class="far fa-user"></i>
                    </span>
                </button>
            </div>
            <div v-if="$parent.$parent.$parent.show_customers_dropdown" class="dropdown-menu customers-dropdown-menu" id="dropdown-menu6" style="z-index:9999;" role="menu" >
                <div class="dropdown-content bg-amber-200 pt-0" style="position:relative;">
                    <button @click.prevent="toggle_customers_dropdown" class="delete is-small" style="position:absolute; top:5px; right:5px; z-index:9999;"></button>
                    <div class="dropdown-item pt-1 pl-2" style="">
                        <div v-if="!$parent.$parent.$parent.document.selected_customer.id" class="pt-5">
                            <div v-if="!show_create_form">
                                <div class="field has-addons has-addons-centered">
                                    <p class="control ">
                                        <a @click.prevent="start_create_customer()" href="" class="button is-small">
                                            <i class="fa-solid fa-user-plus"></i>
                                        </a>
                                    </p>
                                    <p class="control is-flex-grow-1">
                                        <input ref="customer_name" v-model="search_customer_val" @keyup.enter="search_customer()" class="input is-small" type="text" placeholder="Kerko klient...">
                                    </p>
                                    <p class="control">
                                        <a v-if="!search_loading" @click.prevent="search_customer()" class="button bg-yellow-600 text-white is-small">
                                            <i class="fas fa-search"></i>
                                        </a>
                                        <a v-else class="button bg-yellow-600 text-white is-small">
                                            <i class="fa fa-spinner fa-spin"></i>
                                        </a>
                                    </p>
                                </div>
                                <table v-if="search_results.length > 0" class="table is-fullwidth is-bordered is-narrow">
                                    <tbody>
                                        <tr v-for="(customer, index) in search_results" :key="index">
                                            <td >
                                                <p>
                                                    <!-- <span v-if="customer.is_business == 0">
                                                        {{customer.firstname}} {{customer.lastname}}
                                                    </span>
                                                    <span v-else>
                                                        {{customer.business_name}}
                                                    </span> -->
                                                    {{customer.firstname}} {{customer.lastname}}
                                                </p>
                                                <small class="text-slate-400">Kodi: {{customer.code}} </small>
                                            </td>
                                            <td>
                                                {{customer.business_name != 0 ? customer.business_name : ''}}
                                            </td>
                                            <td style="vertical-align:middle;">
                                                <a @click.prevent="select_customer(customer)" href="" class="button is-primary is-small is-fullwidth">
                                                    <i class="fa-regular fa-square-check"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div v-else class="create-customer-wrap has-background-white p-3">
                                <!-- <button @click.prevent="close_create_customer" class="delete close-create-btn"></button> -->
                                <a @click.prevent="close_create_customer" class="is-pulled-right" href=""><i class="fa-solid fa-xmark"></i></a>
                                 <br>
                                <DaForm v-if="form_create" @submitted="submitCustomer" :form="form_create">
                                </DaForm>
                            </div>
                        </div>
                        <div v-else>
                            <div class="is-flex">
                                <div class=" pr-4 pl-0">
                                    <span @click.prevent="remove_selected_user()" class="fa-stack" style="position:relative; padding-left:5px;">
                                        <i class="fa-regular fa-user" style="font-size:14px;"></i> 
                                        <i class="fa-solid fa-xmark has-text-danger" style="position:absolute; top:0px; left:0px; font-size:9px;"></i>
                                    </span>
                                </div>
                                <div class="pt-5 is-flex-grow-1">
                                    <table class="table is-fullwidth">
                                        <tbody>
                                            <tr>
                                                <td>{{$parent.$parent.$parent.document.selected_customer.firstname}}</td>
                                                <td>{{$parent.$parent.$parent.document.selected_customer.lastname}}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <small class="text-slate-400">Kodi: {{$parent.$parent.$parent.document.selected_customer.code}}</small> <br>
                                                    <span>Biznes: {{$parent.$parent.$parent.document.selected_customer.business_name != 0 ? $parent.$parent.$parent.document.selected_customer.business_name : ''}}</span>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "@/services/Api";
import CategoriesServices from "@/services/Category";
// import createCustomer from "@/pages/customers/forms/create";
import GenericServices from "@/services/Generic";
// import helpers from "@/helpers.js";
import DaForm from "@/components/daform/index.vue";
import CustomersServices from "@/services/Customer";
export default {
    components: {
        DaForm
    },
    data() {
        return {
            // show_dropdown: false,
            create_what: 0,
            show_create_form: false,
            search_customer_val: '',
            search_results: [],
            search_loading: false,
            customer_categories: [],
            form_edit: null,
            form_create: null,
            details_types: null
        }
    },
    async created(){
        this.customer_categories = await CategoriesServices.getCategories("customers");
        this.details_types = await GenericServices.getDetailsTypes({ related_entity: "customer" });
    },
    methods: {
        toggle_customers_dropdown(){
            this.$parent.$parent.$parent.show_customers_dropdown = !this.$parent.$parent.$parent.show_customers_dropdown
        },
        start_create_customer(){
            this.show_create_form = true
            this.create_customer()
        },
        close_create_customer(){
            this.show_create_form = false
            this.form_create = null
        },
        search_customer(){
            if(this.search_customer_val != ""){
                this.search_loading = true
                this.search_results = []
                Api(true).post('/customer/search', {search: this.search_customer_val})
                .then(res => {
                    if (res.data.length > 0){
                        res.data.map(rr =>{
                            this.search_results.push(rr)
                        })
                    }
                    this.search_loading = false
                }).catch(() => {
                    this.search_loading = false
                })
            }
        },
        select_customer(c){
            this.$parent.$parent.$parent.document.selected_customer = {...c}
            this.$parent.$parent.$parent.show_customers_dropdown = false
        },
        remove_selected_user(){
            this.$parent.$parent.$parent.document.selected_customer = {}
        },
        create_customer() {
            this.form_edit = null;
            // this.form_create = createCustomer.form(
            //     helpers.toTree(this.customer_categories, "id"),
            //     this.details_types
            // );
            this.form_create = {
                name: 'Krijo klient',
                submit: {
                    label: 'Krijo',
                    action: 'submitCustomer'
                },
                fields: [
                    [
                        {
                            value: '', 
                            name: 'firstname', 
                            label: 'Emri', 
                            type: 'text-field', 
                            style: 'flex-grow: 0.2;',
                            required: true,
                            required_text: 'Kjo fushe eshte e detyrueshme'
                        },
                        {
                            value: '', 
                            name: 'lastname', 
                            label: 'Mbiemri', 
                            type: 'text-field', 
                            style: 'flex-grow: 0.4;',
                            required: true,
                            required_text: 'Kjo fushe eshte e detyrueshme'
                        },
                    ],
                    [
                        {
                            value: '', 
                            name: 'customer-cel', 
                            label: 'Nr. Tel', 
                            type: 'number-field',
                            style: 'flex-grow: 1;',
                            required: false,
                            // required_text: 'Kjo fushe eshte e detyrueshme'
                        },
                    ]
                ]
            }
        },
        async submitCustomer(f){
            f = {...f, simple: 1}
            let customer = await CustomersServices.createCustomer(f)
            this.$parent.$parent.$parent.document.selected_customer = customer
            this.form_create = null
            this.show_create_form = false
        }
    },
}
</script>

<style>
    .sales-customers-wrap{
        /* position:absolute;
        top:10px;
        right:10px; */
    }
    .show-customers-btn{
        border-radius:50%!important;
        width:30px;
        height:30px;
    }
    .customers-dropdown-menu{
        min-width:430px!important;
    }
    .create-customer-wrap{
        position:relative;
    }
    .close-create-btn{
        position:absolute;
        top:0px;
        right:0px;
    }

</style>