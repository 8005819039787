import { useDocumentStore } from "@/stores/document";
import { storeToRefs, mapActions } from 'pinia';
import Api from "@/services/Api";
import permissionMixin from '@/mixins/permissions-mixin.js'
export default {
    data() {
        return {
            document: storeToRefs(useDocumentStore()),
        }
    },
    mixins: [permissionMixin],
    methods: {
        ...mapActions(useDocumentStore, ['editDetails']),
        async create_edit_document_mixin(doc_type){
            var response = {
                success: false,
                message: '',
            }
            // If POS NOT selected
            if(!this.document[doc_type + '_info'].pos_id){
                // return response
                response = {
                    success: false,
                    message: 'no_pos',
                }
            } else {
                var can_proceed = false
                var missing_permissions = "";
                if(!this.document[doc_type + '_info'].guid){
                    // Can Create draft / order
                    if( this.am_allowed(this.$route.params.document + '.can_create') ){
                        can_proceed = true
                    }
                    else {
                        missing_permissions = 'create'
                    }
                } else {
                    // Can Edit draft / order
                    if(this.am_allowed(this.$route.params.document + '.can_edit')){
                        can_proceed = true
                    } 
                    else {
                        missing_permissions = 'edit'
                    }
                }
                if(!can_proceed){
                    // var type = this.$route.params.document == 'draft' ? 'draft' : this.$route.params.document == 'order' ? 'porosi' : ''
                    
                    this.document[doc_type + '_cart'] = [...this.document[doc_type + '_cart'].map(p => {
                        p.amount = p.amount_original
                        // p.is_transportable = p.is_transportable_original
                        // p.is_mountable = p.is_mountable_original
                        return p
                    })]
                    
                    return response = {
                        success: false,
                        can_proceed,
                        message: 'missing_permission',
                        missing_permissions
                    }
                    // TO DO: Revert changes in frontend 

                } 
                // If have permissions
                else {
                    await Api(true).post('/invoice/draft/create', {
                        cart: this.document[doc_type + '_cart'], 
                        info: {
                            'customer_id': this.document.selected_customer.guid ? this.document.selected_customer.guid : 0,
                            'pos_id': this.document[doc_type + '_info'].pos_id,
                            'guid': this.document[doc_type + '_info'].guid,
                        },
                        document_type: this.$route.params.document
                    }).then(async res => {
                        var is_new = false
                        if(!this.document[doc_type + '_info'].guid) is_new = true
                        this.document[doc_type + '_info'] = {...res.data.draft_info[0]}
                        
                        // this.document[doc_type + '_cart'] = [...res.data.draft_details.map(d => {
                        //     d.amount = parseFloat(d.amount)
                        //     d.discount = parseFloat(d.discount)
                        //     d.show_notes = false
                        //     return {...d}
                        // })]

                        var details = [] 
                        details = [
                            ...res.data.draft_details.map((d) => {
                                d.amount = parseFloat(d.amount);
                                d.discount = parseFloat(d.discount);
                                d.show_notes = false;
                                return { ...d };
                            }),
                        ];
                        await this.editDetails(doc_type, details)


    
                        response = {
                            success: true, 
                            can_proceed,
                            is_new: is_new,
                            message: 'success_request',
                            res
                        }
                        
                    })
                    .catch(error => {
                        console.log('error', error)
                        response = {
                            success: false,
                            server_error: true,
                            message: 'server_error'
                        }
                        // this.$toast.error('Dicka nuk punoi.')
                    })

                }
            }
            return response
        },

        // adds a product to the cart in the document store
        async add_to_cart_mixin(p){
            
            var can_proceed = false
            var missing_permissions = []

            // If is new document
            if(!this.document[this.$route.params.document + '_info'].guid){
                if( this.am_allowed(this.$route.params.document + '.can_create') ){ 
                    can_proceed = true
                }
                else missing_permissions = 'create'
            }
            // If is edit document
            else {
                if( this.am_allowed(this.$route.params.document + '.can_edit') ){
                    can_proceed = true
                }
                else missing_permissions = 'edit'
            }

            if(!can_proceed) 
                return {
                    success: false,
                    message: 'missing_permission', 
                    missing_permissions
                }
            if(!this.document[this.document.current_doc_type + '_info'].pos_id){
                // this.$toast.error('Zgjidh POS')
                return {
                    success: false, 
                    message: 'no_pos'
                }
                // this.$parent.show_customers_dropdown = true
            } else {
                // Add new product or increse amount of already added product
                this.document[this.document.current_doc_type + '_cart'].push({ 
                    ...p,
                    guid: null,
                    product_id: p.product_id, 
                    amount: 1, 
                    discount: 0,
                })
                return {
                    success: true, 
                    is_new: true
                }
            }

        },
        
    },
}