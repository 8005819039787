
<template>
  <div>
    <vue-final-modal
      v-slot="{ close }"
      v-bind="$attrs"
      classes="modal-container"
      content-class="modal-content"
      @beforeOpen="(event) => event.ref.params"
    >
      <button class="modal__close" @click="close">
        <i class="fa fa-times"></i>
      </button>
      <span class="modal__title"
        >Shiten bashke me {{ $vfm.dynamicModals[0].params["doc_detail"].name }}
      </span>
      <hr class="my-1" />
        <div v-if="is_loading" class="text-center">
            <div class="py-3 bg-slate-50">
                <i class="fa fa-spinner fa-spin"></i>
            </div>
        </div>
        <div v-else>
            <div
                v-for="(accessory_category, index) in accessories_category_list"
                :key="index" >
                <div class="bg-slate-200 px-2 mb-1">{{ accessory_category.accessory_name }}</div>
                <div>
                    <ul class="mb-2">
                        <li v-for="(accessory, indexa) in accessories_of_category[
                            accessory_category.accessory_id
                            ]"
                            :key="indexa" 
                            class="ml-2 pl-2 is-flex is-align-items-center
                                hover:bg-slate-100  border-l border-slate-200">
                            <p>
                            {{ accessory.name }}
                            <small class="is-align-content-self-end text-slate-400">({{amount_filter(accessory.amount)}} x)</small>

                            </p>
                            <a @click.prevent="add_accessory(accessory)"
                                class="is-pulled-right text-green-500 ml-auto px-2 py-1" href="">
                                <i class="fa-solid fa-plus"></i>
                            </a>
                            <!-- <pre>{{accessory}}</pre> -->
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </vue-final-modal>
  </div>
</template>
<script>
import { VueFinalModal } from "vue-final-modal";
import Api from "../../../services/Api";
import { useDocumentStore } from "@/stores/document";
import { storeToRefs } from "pinia";
import documentMixin from "@/mixins/document-mixin.js";
var numeral = require('numeral');
export default {
  mixins: [documentMixin],
  components: { VueFinalModal },
  created() {
    this.get_product_accessories();
  },
  data() {
    return {
      notes: [],
      show_create_new: false,
      new_note: {},
      document: storeToRefs(useDocumentStore()),
      accessories_list: [],
      is_loading: false
    };
  },
  methods: {
    get_product_accessories() {
      if (this.accessories_category_list.length > 0) {
        this.is_loading = true
        Api(true)
          .post("/document/get/product/accessories", {
            accessories_category_list: this.accessories_category_list,
            pos: this.document[this.document.current_doc_type + "_info"].pos_id,
          })
          .then((res) => {
            this.accessories_list = res.data;
            this.is_loading = false
          });
      }
    },
    async add_accessory(p) {
      var add_ca = await this.add_to_cart_mixin(p);
      if (!add_ca.success) {
        if (add_ca.message == "no_pos") {
          this.$toast.error("Zgjidh POS");
        } else if (add_ca.message == "missing_permission") {
          this.$toast.error(
            "Ju nuk keni te drejta per te " +
              (add_ca.missing_permissions == "create"
                ? "krijuar"
                : "modifikuar") +
              " nje dokument!"
          );
        }
        // this.$parent.show_customers_dropdown = true
      } else {
        // Save dhe document
        // this.is_saving = true
        await this.create_invoice_draft();
        // this.is_saving = false

        // this.show_product_search = false
        // this.search_results = []
      }
    },
    async create_invoice_draft() {
      var document_create_edit = await this.create_edit_document_mixin(
        this.document.current_doc_type
      );
      if (document_create_edit.message == "no_pos") {
        this.$toast.error("Zgjidh POS."); // Zgjidh pos
      }
      // If POS is selected
      else {
        // If have permissions to proceed
        if (document_create_edit.can_proceed) {
          // If Server error (500)
          if (document_create_edit.server_error) {
            this.$toast.error(
              document_create_edit.message == "server_error"
                ? "Server error"
                : ""
            );
          } else {
            // If have permissions AND Success call
            if (document_create_edit.success) {
              // this.$toast.success(document_create_edit.message == 'success_request' ? 'aaa Ndryshimi u ruajt me sukses.' : '') // Ndryshimi juaj u ruajt me sukses

              this.$toast.show(
                document_create_edit.message == "success_request"
                  ? "Ndryshimi u ruajt me sukses."
                  : "",
                {
                  ...{
                    // duration: false,
                    type: "success",
                    position: "bottom-left",
                  },
                }
              );

              // Call Socket
            //   var socket_name = document_create_edit.is_new
            //     ? "create_new_" + this.$route.params.document
            //     : "edit_" +
            //       this.$route.params.document +
            //       "_" +
            //       this.document.draft_info.guid;
            //   socket.emit("propagate", {
            //     data: document_create_edit.res.data,
            //     action: socket_name,
            //     document_type: this.$route.params.document,
            //     // user:
            //   });
            }
          }
        }
        // Not allowed from permissions
        else {
          // When permissions doesn't allow
          if (document_create_edit.message == "missing_permission") {
            this.$toast.error(
              "Ju nuk keni te drejta per te " +
                (document_create_edit.missing_permissions == "create"
                  ? "krijuar"
                  : "modifikuar") +
                " nje dokument!"
            );
          }
        }
      }
    },
    amount_filter(price){
        return numeral(price).format('0,0')
    },
  },
  computed: {
    accessories_category_list() {
      return this.$vfm.dynamicModals[0].params["doc_detail"]
        .accessories_categories;
    },
    accessories_of_category() {
      var data = {};
      this.accessories_list.map((a) => {
        if (!data[a.accessory_id]) data[a.accessory_id] = [];
        data[a.accessory_id].push(a);
      });
      return data;
    },
  },
};
</script>

<style scoped>
hr {
  border: 1px solid #ccc;
  margin: 10px 0;
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__content {
  padding: 10px;
  font-size: 22px;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.notes-action-icons {
  font-size: 10px;
  padding: 4px 6px;
}
</style>

<style scoped>
.dark-mode div::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>